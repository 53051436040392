<template>
  <section class="app-ecommerce-details">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="fetchData && (collateral === null || collateral === undefined)"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link
          class="alert-link"
        >
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>

    <b-card
      v-if="collateral"
      no-body
    >
      <b-card-body>
        <b-row class="my-2">

          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <!-- <carousel
                :starting-image="0"
                :images="productImages"
              /> -->
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="7"
          >

            <!-- Product Name -->
            <h4>{{ collateral.productName }}</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <span>by</span>
              <b-link class="company-name">
                {{ collateral.productBrand }}
              </b-link>
            </b-card-text>

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">
                ${{ collateral.productName }}
              </h4>
            </div>

            <!-- Avability -->
            <b-card-text>Available - <span class="text-success">In stock</span></b-card-text>

            <!-- Product Description -->
            <b-card-text>{{ collateral.otherInformation }}</b-card-text>

            <!-- Product Meta [Free shpping, EMI, etc.] -->
            <ul class="product-features list-unstyled">
              <li v-if="collateral.hasFreeShipping">
                <feather-icon icon="ShoppingCartIcon" />
                <span>Free Shipping</span></li>
              <li>
                <feather-icon icon="DollarSignIcon" />
                <span>EMI options available</span>
              </li>
            </ul>

            <hr>

            <!-- Colors -->
            <div class="product-color-options">
              <h6>Colors</h6>
              <ul class="list-unstyled mb-0">
                <li
                  v-for="color in collateral.colorOptions"
                  :key="color"
                  class="d-inline-block"
                  :class="{'selected': selectedColor === color}"
                  @click="selectedColor = color"
                >
                  <div
                    class="color-option"
                    :class="`b-${color}`"
                  >
                    <div
                      class="filloption"
                      :class="`bg-${color}`"
                    />
                  </div>
                </li>
              </ul>
            </div>

            <hr>

            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>{{ collateral.isInCart ? 'View In Cart' : 'Add to Cart' }}</span>
              </b-button>
              <b-button
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
              >
                <feather-icon
                  icon="HeartIcon"
                  class="mr-50"
                  :class="{'text-danger': collateral.isInWishlist}"
                />
                <span>Wishlist</span>
              </b-button>
              <b-dropdown
                variant="outline-secondary"
                no-caret
                toggle-class="btn-icon"
                class="btn-share"
                right
              >
                <template #button-content>
                  <feather-icon icon="Share2Icon" />
                </template>
                <b-dropdown-item
                  v-for="icon in [ 'FacebookIcon', 'TwitterIcon', 'YoutubeIcon', 'InstagramIcon', ]"
                  :key="icon"
                >
                  <feather-icon :icon="icon" />
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

    </b-card>
  </section>
</template>

<script>
import store from '@/store'
import {
  BCard, BCardBody, BRow, BCol, /* BImg,  */BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
// import Carousel from '@/views/components/Carousel.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    // BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    // Carousel,
  },
  data() {
    return {
      productImages: [],
      fetchData: false,
    }
  },
  computed: {
    ...mapGetters({
      collateral: 'getCollateral',
    }),
  },
  watch: {
    collateral(newVal) {
      this.productImages = newVal.productImages.map(image => ({ id: image.name, big: image.url }))
    },
  },
  mounted() {
    store.dispatch('fetchProduct', { pid: this.$route.params.pid }).then(() => {
      this.fetchData = true
    })
    this.defaultSettings()
  },
  methods: {
    async defaultSettings() {
      this.productImages = this.collateral.productImages.map(image => ({ id: image.name, big: image.url }))
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
